<template>
	<div class="update">
		<div
			v-if="!submitsuccess"
			class="min-w-screen bg-gray-800 bg-opacity-75 flex flex-col gap-9 px-5 py-10"
		>
			<h1 class="text-4xl font-bold text-center">Submit Run</h1>
			<div
				v-if="errorstatus"
				class="w-full self-center max-w-xs bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
				role="alert"
			>
				<span class="block sm:inline">{{ errorstatus }}</span>
				<span class="absolute top-0 bottom-0 right-0 px-4 py-3">
					<svg
						@click="errorstatus = null"
						class="fill-current h-6 w-6 text-red-500"
						role="button"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
					>
						<title>Close</title>
						<path
							d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
						/>
					</svg>
				</span>
			</div>
			<!--Verify-->
			<div
				v-if="!verified"
				class="flex justify-center"
				v-on:keyup.enter="verify"
			>
				<div class="w-full max-w-xs">
					<div class="mb-4">
						<input
							v-model="id_num"
							class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type="text"
							placeholder="ID card Number"
							:disabled="submiting ? true : false"
						/>
					</div>
					<div class="mb-6">
						<input
							v-model="bib_num"
							class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type="number"
							placeholder="BIB"
							:disabled="submiting ? true : false"
						/>
					</div>
					<div class="flex justify-center">
						<button
							v-if="!submiting"
							@click="verify"
							class="bg-red-800 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="button"
						>
							Verify
						</button>
						<button
							v-if="submiting"
							class="bg-red-800 text-white font-bold py-2 px-4 flex items-center rounded focus:outline-none focus:shadow-outline cursor-wait"
							type="button"
						>
							<span>Verifying</span>
							<svg
								class="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									class="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									stroke-width="4"
								></circle>
								<path
									class="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								></path>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<!--UploadData-->
			<div v-if="verified" class="flex justify-center relative">
				<div class="w-full max-w-xs">
					<div class="mb-4">
						<p>Name: {{ fullname }}</p>
						<p>ID no: {{ id_num }}</p>
						<p>Sequence no: {{ bib_num }}</p>
					</div>
					<div class="mb-4">
						<label for="">Distance (km)</label>
						<input
							v-model="distance"
							class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type="number"
							placeholder="Distance"
							autofocus
							:disabled="submiting ? true : false"
						/>
					</div>
					<div class="mb-4">
						<label for="">Screenshot</label>
						<input
							v-on:change="handlefile"
							class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
							type="file"
							accept="image/*"
							:disabled="submiting ? true : false"
						/>
					</div>
					<button
						v-if="!submiting"
						@click="submitrun"
						class="bg-red-800 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
						type="button"
					>
						Submit
					</button>
					<button
						v-if="submiting"
						class="bg-red-800 text-white font-bold py-2 px-4 flex items-center rounded focus:outline-none focus:shadow-outline cursor-wait"
						type="button"
					>
						<span>Submiting</span>
						<svg
							class="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								class="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								stroke-width="4"
							></circle>
							<path
								class="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							></path>
						</svg>
					</button>
				</div>
			</div>
		</div>
		<div
			v-else
			class="min-w-screen bg-gray-800 bg-opacity-75 flex flex-col gap-9 px-5 py-10 items-center"
		>
			<div class="flex">
				<p>Submitted Successfully</p>
				<svg
					class="ml-2 text-green-300"
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentcolor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				>
					<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
					<polyline points="22 4 12 14.01 9 11.01"></polyline>
				</svg>
			</div>
			<div v-if="$store.getters.isLoggedIn">
				<button
					@click="this.clearall"
					class="bg-red-800 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
					type="button"
				>
					New
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isLoading: true,
				verified: false,
				id_num: "",
				bib_num: "",
				distance: null,
				fullname: "",
				errorstatus: null,
				screenshot: null,
				submitsuccess: false,
				submiting: false,
			};
		},
		methods: {
			clearall() {
				this.isLoading = true;
				this.verified = false;
				this.id_num = "";
				this.bib_num = "";
				this.distance = null;
				this.fullname = "";
				this.errorstatus = null;
				this.screenshot = null;
				this.submitsuccess = false;
				this.submiting = false;
			},
			verify() {
				this.submiting = true;
				this.$http
					.post(process.env.VUE_APP_API_ROOT + "/verify", {
						nid: this.id_num,
						bib: this.bib_num,
					})
					.then(this.verifiedfn)
					.catch(this.verifyerror);
			},
			verifiedfn(res) {
				this.submiting = false;
				if (res.data.verify == "submited")
					this.errorstatus = "Already submited";
				else {
					this.fullname = res.data.name;
					this.id_num = res.data.nid;
					this.bib_num = res.data.bib;
					this.errorstatus = null;
					this.verified = true;
				}
			},
			verifyerror(err) {
				this.submiting = false;
				this.errorstatus = err.response.data.msg;
			},
			submitrun() {
				if (this.distance) {
					let data = new FormData();
					data.append("bib", this.bib_num);
					data.append("nid", this.id_num);
					data.append("distance", this.distance);
					data.append("screenshot", this.screenshot);
					this.submiting = true;
					this.$http
						.put(process.env.VUE_APP_API_ROOT + "/runner", data)
						.then(this.handlesubmit)
						.catch(this.verifyerror);
				} else {
					this.errorstatus = "Missing fields";
				}
			},
			handlesubmit(result) {
				this.submiting = false;
				if (result.data.msg == "success") this.submitsuccess = true;
				else this.errorstatus = "Missing fields";
			},
			handlefile(files) {
				this.screenshot = files.target.files[0];
			},
		},
	};
</script>
